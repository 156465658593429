const LOCATIONS_DATA = {
    locations: [
        {
            title: 'Optum Allergy',
            mapUrl: 'https://www.google.com/maps/embed/v1/place?q=place_id:ChIJO9JyFYi4yIARbZ65rPu9T38&key=AIzaSyAjS1V-2CtBZj4tKu4Y4awhW6iWLti8D4E&zoom=15',
            linkUrl: 'https://www.google.com/maps?ll=36.076087,-115.297047&z=15&t=m&hl=en-US&gl=US&mapclient=embed&q=6190+S+Fort+Apache+Rd+Las+Vegas,+NV+89148',
            address1: '6190 S. Fort Apache Rd.',
            address2: 'Las Vegas, NV 89148',
            address3: '',
            hours: 'Monday–Friday, 8 a.m–5 p.m.',
            phone: '1-702-724-8844',
            id: 1
        },
        {
            title: 'Optum Allergy',
            mapUrl: 'https://www.google.com/maps/embed/v1/place?q=place_id:EkhQYXNlbyBWZXJkZSBQbGF6YSwgNzUgUyBWYWxsZSBWZXJkZSBEciBzdGUgMTAwLCBIZW5kZXJzb24sIE5WIDg5MDEyLCBVU0EiIxohChYKFAoSCQm2LBSW0ciAEZZDyM-4wdFeEgdzdGUgMTAw&key=AIzaSyAjS1V-2CtBZj4tKu4Y4awhW6iWLti8D4E&zoom=15',
            linkUrl: 'https://maps.app.goo.gl/MFmtXJZza9zZvuUEA',
            address1: '75 S. Valle Verde Dr., Ste. 100',
            address2: 'Henderson, NV 89012',
            address3: '',
            hours: 'Monday–Friday, 8 a.m–5 p.m.',
            phone: '1-702-724-8844',
            id: 2
        },
        {
            title: 'Optum Pulmonology',
            mapUrl: 'https://www.google.com/maps/embed/v1/place?q=place_id:ChIJWcBJxvDByIARFwHhnzogk0o&key=AIzaSyAjS1V-2CtBZj4tKu4Y4awhW6iWLti8D4E&zoom=15',
            linkUrl: 'https://maps.app.goo.gl/sXxVgk28fAYEfRmJ7',
            address1: '4750 W. Oakey Blvd., Fl. 2',
            address2: 'Las Vegas, NV 89102',
            address3: '',
            hours: 'Monday–Friday, 8 a.m–5 p.m.',
            phone: '1-702-724-8844',
            id: 3
        },
        {
            title: 'Optum Pulmonology Valle Verde',
            mapUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3226.8276353364977!2d-115.06625492337476!3d36.02450227247844!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c8ce044e95bb63%3A0xc871fd6c4376638e!2sOptum%20Allergy%20at%20Valle%20Verde!5e0!3m2!1ses-419!2smx!4v1731367322900!5m2!1ses-419!2smx',
            linkUrl: 'https://maps.app.goo.gl/FQ4fJmvBzos7Gd8h9',
            address1: '75 S. Valle Verde Dr., Ste. 100',
            address2: 'Henderson, NV 89012',
            address3: '',
            hours: 'Monday–Friday, 8 a.m–5 p.m.',
            phone: '1-702-724-8844',
            id: 4
        },
    ]
}

export default LOCATIONS_DATA;